export default {
  s3: {
    REGION: "eu-west-2",
    BUCKET: "sportsbalcony.com"
  },
  apiGateway: {
    REGION: "eu-west-2",
    URL: "'https://a6y7fk20tj.execute-api.eu-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_CdSxbYAwa",
    APP_CLIENT_ID: "6nvhkgac4oo9dana5rf16ncd8p",
    IDENTITY_POOL_ID: "eu-west-2:b931790d-6400-4446-ae14-d256f3ad36ab"
  }
};
