import React, { Component, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import "./App.css";
import Routes from "./Routes";
import { Auth } from "aws-amplify";
import image from "./fakegrass.jpg";

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      navExpanded: false
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }

    this.setState({ isAuthenticating: false });
  }

  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  }

  handleLogout = async event => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/login");
  }

  setNavExpanded = expanded => {
    this.setState({ navExpanded: expanded });
  }

  closeNav = ex => {
    console.log("nav closed");
    this.setState({ navExpanded: false });
  }

  render() {
    const childProps = {
    isAuthenticated: this.state.isAuthenticated,
    userHasAuthenticated: this.userHasAuthenticated
    };

    return (
      <div className="App flexcontainer">
          <Navbar onToggle={this.setNavExpanded}
                expanded={this.state.navExpanded}>
          <Navbar.Header>
            <Navbar.Brand>
              <Link to="/">Sports Balcony</Link>
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
            <Navbar.Collapse>
              <Nav pullRight>
              {this.state.isAuthenticated
                ?
                  <Fragment>
                  <LinkContainer to="/settings">
                  <NavItem onSelect={this.closeNav}>Settings</NavItem>
                  </LinkContainer>
                  <NavItem onClick={this.handleLogout}>Logout</NavItem>
                  </Fragment>
                : <Fragment>
                    <LinkContainer to="/signup">
                      <NavItem onSelect={this.closeNav}>Signup</NavItem>
                    </LinkContainer>
                    <LinkContainer to="/login">
                      <NavItem onSelect={this.closeNav}>Login</NavItem>
                    </LinkContainer>
                  </Fragment>
              }
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        <div className="scrolling-box">
          <section id="main">
            <Routes childProps={childProps} />
          </section>
          <section id="aboutus">
            <h1>Who we are</h1>
            <p>
               "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </section>
          <section id="contactus">
            <h1>Get in touch</h1>
            <p>
               "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
            </p>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
